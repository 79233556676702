import React from 'react';
import { obj } from '../../CategoryPage/imageObj';
import './body.css'

function ItemBody({ sortedReferenceData, sortedProductData, subCategory }) {

    return (
        <>
            <div className='item-image-container'>
                <img className='item-image' src={obj[subCategory]} alt={obj[subCategory]}/>
            </div>
            <div className='ref-data'>
                {sortedReferenceData.length > 0 ? (
                    <>
                        <div className='ref-data-container'>
                            <div className='item-body-header'> Reference Numbers </div>
                            <div className='item-body'>
                                    <div className="column">
                                        {sortedReferenceData.map((item, index) => (
                                            <div key={index}>
                                                {Object.keys(item).map((key) => (
                                                    <div key={key} className="column-key">{key}</div>
                                                    ))}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="column">
                                        {sortedReferenceData.map((item, index) => (
                                            <>
                                                {Object.values(item).map((value) => (
                                                    <div key={value} className="column-value">{value}</div>
                                                    ))}
                                            </>
                                        ))}
                                    </div>
                            </div>
                        </div>
                        <div className='item-body-stick'></div>
                    </>
                ) : null}
            </div>
            <div className='product-data'>
            {sortedProductData.length > 0 ? (
                <>
                    <div className='item-body-header'> Product Details </div>
                    <div className='item-body'>
                        <div className="column">
                            {sortedProductData.map((item, index) => (
                                <div key={index}>
                                    {Object.keys(item).map((key) => (
                                        <div key={key} className="column-key">{key}</div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className="column">
                            {sortedProductData.map((item, index) => (
                                <div key={index}>
                                    {Object.entries(item).map(([key, value]) => (
                                        <div key={key} className="column-value">
                                            {/* {key === 'Flange O.D.' || key === 'I.D.' || key === 'O.D.' || key === 'Width' ? value + '"' : value} */}
                                            {/* {key === 'Pack' || key === 'Strength' ? value :value.includes('"') ? value.replace(/"/g, '') + '"' : value} */}
                                            {(["Flange O.D.", "I.D.", "O.D.", "Width", "Flange Thickness", 'Thickness'].includes(key) && !value.includes('mm')) ? (value + '"') : value}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='item-button-container'>
                        <a href='/contact' className='item-button'>
                            Contact
                        </a>
                    </div>
                </>
                ) : null}
            </div>
        </>
    );
}

export default ItemBody;
