import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

import './breadcrumb.css'


export const plHeader = (
    <div class="breadcrumb">
        <NavLink to='/' style={{ textDecoration: 'none', color: '#242B32' }}>
            <p class="former-state">Home</p>
        </NavLink>
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_745_3641)">
            <path d="M2.66667 0.666707L6 4.00004L2.66667 7.33337L2.075 6.74171L4.81667 4.00004L2.075 1.25837L2.66667 0.666707Z" fill="#3D4647"/>
            </g>
            <defs>
            <clipPath id="clip0_745_3641">
            <rect width="8" height="8" fill="white" transform="translate(8 8) rotate(180)"/>
            </clipPath>
            </defs>
            </svg>
        <p class="current-state">Product Line Card</p>
    </div>
)

export const aboutHeader = (

    <div class="breadcrumb">
        <NavLink to='/' style={{ textDecoration: 'none', color: '#242B32' }}>
            <p class="former-state">Home</p>
        </NavLink>
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_745_3641)">
            <path d="M2.66667 0.666707L6 4.00004L2.66667 7.33337L2.075 6.74171L4.81667 4.00004L2.075 1.25837L2.66667 0.666707Z" fill="#3D4647"/>
            </g>
            <defs>
            <clipPath id="clip0_745_3641">
            <rect width="8" height="8" fill="white" transform="translate(8 8) rotate(180)"/>
            </clipPath>
            </defs>
            </svg>
        <p class="current-state">About</p>
    </div>

)

export const contactHeader = (
    <div class="breadcrumb">
        <NavLink to='/' style={{ textDecoration: 'none', color: '#242B32' }}>
            <p class="former-state">Home</p>
        </NavLink>
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_745_3641)">
            <path d="M2.66667 0.666707L6 4.00004L2.66667 7.33337L2.075 6.74171L4.81667 4.00004L2.075 1.25837L2.66667 0.666707Z" fill="#3D4647"/>
            </g>
            <defs>
            <clipPath id="clip0_745_3641">
            <rect width="8" height="8" fill="white" transform="translate(8 8) rotate(180)"/>
            </clipPath>
            </defs>
            </svg>
        <p class="current-state">Contact</p>
    </div>
)




export function CategoryHeader({ category }) {

    return (
        <div class="breadcrumb">
            <NavLink to='/' style={{ textDecoration: 'none', color: '#242B32' }}>
                <p class="former-state">Home</p>
            </NavLink>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_745_3641)">
                <path d="M2.66667 0.666707L6 4.00004L2.66667 7.33337L2.075 6.74171L4.81667 4.00004L2.075 1.25837L2.66667 0.666707Z" fill="#3D4647"/>
                </g>
                <defs>
                <clipPath id="clip0_745_3641">
                <rect width="8" height="8" fill="white" transform="translate(8 8) rotate(180)"/>
                </clipPath>
                </defs>
                </svg>
                <p class="current-state">{category}</p>
        </div>
    )
}

export function SubCategoryHeader({ category, subCategory, allProducts }) {

    let cats = allProducts.filter(item => item.Category.S === category)
    let subCats = Array.from(new Set(cats.map((item) => item["Sub-Category"].S)));


    return (
        <div class="breadcrumb">
            <NavLink to='/' style={{ textDecoration: 'none', color: '#242B32' }}>
                <p class="former-state">Home</p>
            </NavLink>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_745_3641)">
                <path d="M2.66667 0.666707L6 4.00004L2.66667 7.33337L2.075 6.74171L4.81667 4.00004L2.075 1.25837L2.66667 0.666707Z" fill="#3D4647"/>
                </g>
                <defs>
                <clipPath id="clip0_745_3641">
                <rect width="8" height="8" fill="white" transform="translate(8 8) rotate(180)"/>
                </clipPath>
                </defs>
            </svg>
            {subCats.length >= 2 ? (
                <>
                    <NavLink to={`/Product-Line-Card/${category}`} style={{ textDecoration: 'none', color: '#242B32' }}>
                        <div className='former-state'>
                            {category}
                        </div>
                    </NavLink>
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_745_3641)">
                        <path d="M2.66667 0.666707L6 4.00004L2.66667 7.33337L2.075 6.74171L4.81667 4.00004L2.075 1.25837L2.66667 0.666707Z" fill="#3D4647"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_745_3641">
                        <rect width="8" height="8" fill="white" transform="translate(8 8) rotate(180)"/>
                        </clipPath>
                        </defs>
                    </svg>
                </>
            ) : null}
            <p class="current-state">{subCategory[0]}</p>
        </div>
    )
}




export function ItemHeader({ category, subCategory, partNumber, allProducts }) {

    let cats = allProducts.filter(item => item.Category.S === category)
    let subCats = Array.from(new Set(cats.map((item) => item["Sub-Category"].S)));


    return (

        <div class="breadcrumb">
            <NavLink to='/' style={{ textDecoration: 'none', color: '#242B32' }}>
                <p class="former-state">Home</p>
            </NavLink>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_745_3641)">
                <path d="M2.66667 0.666707L6 4.00004L2.66667 7.33337L2.075 6.74171L4.81667 4.00004L2.075 1.25837L2.66667 0.666707Z" fill="#3D4647"/>
                </g>
                <defs>
                <clipPath id="clip0_745_3641">
                <rect width="8" height="8" fill="white" transform="translate(8 8) rotate(180)"/>
                </clipPath>
                </defs>
            </svg>
            {subCats.length >= 2 ? (
                <>
                    <NavLink to={`/Product-Line-Card/${category}`} style={{ textDecoration: 'none', color: '#242B32' }}>
                        <div className='former-state'>
                            {category}
                        </div>
                    </NavLink>
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_745_3641)">
                        <path d="M2.66667 0.666707L6 4.00004L2.66667 7.33337L2.075 6.74171L4.81667 4.00004L2.075 1.25837L2.66667 0.666707Z" fill="#3D4647"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_745_3641">
                        <rect width="8" height="8" fill="white" transform="translate(8 8) rotate(180)"/>
                        </clipPath>
                        </defs>
                    </svg>
                </>
            ) : null}
            <NavLink to={`/Product-Line-Card/${category}/${subCategory[0][1]}`} style={{ textDecoration: 'none', color: '#242B32' }}>
                <p class="former-state">{subCategory[0][0].length > 0 ? subCategory[0][0] : subCategory[0][1]}</p>
            </NavLink>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_745_3641)">
                    <path d="M2.66667 0.666707L6 4.00004L2.66667 7.33337L2.075 6.74171L4.81667 4.00004L2.075 1.25837L2.66667 0.666707Z" fill="#3D4647"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_745_3641">
                    <rect width="8" height="8" fill="white" transform="translate(8 8) rotate(180)"/>
                    </clipPath>
                    </defs>
            </svg>
            <p class="current-state">{partNumber}</p>
        </div>
    )
}
