import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import NavBar from '../../Components/navbar';
// import { loaderGif } from '../../Components/loadingPage/index'
import Footer from '../../Components/footer/footer';
import { aboutHeader } from '../../Components/breadcrumb';
import './about.css'
import building from '../../Images/EUREKA-PHOTO.png'


function About() {
    const [isLoaded, setIsLoaded] = useState(false)
    let extended = true

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true)
        }, 1000)
    }, []);

    return isLoaded && (
        <>
                    <NavBar />
                    <div className='about'>
                    <div className='about-container'>
                        <div className='breadcrumb'>
                            {aboutHeader}
                        </div>
                        <div className='about-header'> About Us </div>
                        <div className='about-body'>
                                <div className='about-text'> Welcome to Eureka Bearing, your trusted industrial solutions provider based in Huntington Beach, California since 1956.</div>
                                <div className='about-text'> At Eureka Bearing, we prioritize prompt service and offer an extensive inventory to meet your needs. With our worldwide access to inventories, we can track down most sizes to ensure your requirements are fulfilled. If you have any questions or can't find what you're looking for, please feel free to <a href='/contact' className='text-link'>contact us</a>.</div>
                                <div className='about-text'> Our dedicated team is available at our fully staffed warehouse, ready to provide personalized assistance when you reach out. Thank you for choosing Eureka Bearing, where exceptional service and comprehensive industrial solutions are our top priorities.</div>
                        </div>
                        <div className='about-dash'> </div>
                    </div>
                    </div>
                    <div className='image-container'>
                        <img className='about-image' src={building} />    
                    </div>
                    <Footer extended={extended}/>
        </>
    )
}

export default About;
