const GET_ALL = 'items/getAll'

const getAll = (payload) => {
    return {
        type: GET_ALL,
        payload
    }
}

export const getAllThunk = () => async dispatch => {
    const response = await fetch('https://79cq8733nh.execute-api.us-east-1.amazonaws.com/dev', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "TableName": "Eureka_DB_11-4" })
    });

    if (response.ok) {
        const data = await response.json();
        dispatch(getAll(data.Items))
    }
    const response2 = await fetch('https://79cq8733nh.execute-api.us-east-1.amazonaws.com/dev', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "TableName": "Eureka_DB_11-4",
            "ExclusiveStartKey": {
                "id": {
                    "S": "2525"
                }
            }
        })
    });
    if (response2.ok) {
        const data = await response2.json();
        dispatch(getAll(data.Items))
    }
    const response3 = await fetch('https://79cq8733nh.execute-api.us-east-1.amazonaws.com/dev', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "TableName": "Eureka_DB_11-4",
            "ExclusiveStartKey": {
                "id": {
                    "S": "2229"
                }
            }
        })
    });
    if (response3.ok) {
        const data = await response3.json();
        dispatch(getAll(data.Items))
    }
}




const initialState = {};
const productReducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case GET_ALL: {
            newState = { ...state };
            if (Array.isArray(action.payload)) {
                action.payload.forEach((item) => {
                    newState[item.id.S] = item;
                });
            }
            return newState;
        }
        default:
            return state;
    }
};

export default productReducer
