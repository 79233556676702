import bbImg from '../ProductLineCard/images/ballbearing.png'
import pnematicTires from '../ProductLineCard/images/pneumatic caster.png'
import precisionFB from '../ProductLineCard/images/image 4.png'
import precisionRB from '../ProductLineCard/images/ballbearing.png'
import needleBearings from '../ProductLineCard/images/needle bearing.png'
import taperImg from '../ProductLineCard/images/taper roller.png'
import needleThWImg from '../ProductLineCard/images/needle-thrust.png'
import steelBallImg from '../ProductLineCard/images/steel balls.png'
import doubleSealFB from '../ProductLineCard/images/double-seal-flanged 1.png'
import hwSeries from '../ProductLineCard/images/hw series 1.png'
import afSeries from '../ProductLineCard/images/af series 1.png'
import hexBoreSeries from '../ProductLineCard/images/hex bore afh 1.png'
import rollerClutchBearing from '../ProductLineCard/images/taper roller.png'
import inchBronzeSleeve from '../ProductLineCard/images/inch_bronze_sleeve.jpg'
import metricBronzeSleeve from '../ProductLineCard/images/metric_bronze_sleeve.jpg'
import inchBronzeFlange from '../ProductLineCard/images/inch_bronze_flange.jpg'
import inchBronzeThrustWasher from '../ProductLineCard/images/inch_bronze_thrust_washer.jpg'
import singleShiftShaftCollar from '../ProductLineCard/images/single-split-shaft-collar.jpg'
import onePieceSetShaftCollar from '../ProductLineCard/images/one-piece-set-screw-shaft-collar.jpg'
import doubleSplitShaftCollar from '../ProductLineCard/images/double-split-shaft-collar.jpg'
import nitrileOil from '../ProductLineCard/images/inch-metric_nitrile_oil_seal.jpg'
import vitonOil from '../ProductLineCard/images/inch-metric_viton_oil_seal.jpg'
import wheel1 from '../ProductLineCard/images/wheel1.png'
import wheel2 from '../ProductLineCard/images/wheel2.webp'
import wheel3 from '../ProductLineCard/images/wheel3.png'
import wheel4 from '../ProductLineCard/images/wheell4.png'
import wheel5 from '../ProductLineCard/images/wheel5.jpeg'
import wheel6 from '../ProductLineCard/images/wheel6.jpeg'
import rollerChain from '../ProductLineCard/images/roller chain.png'
import cableTies from '../ProductLineCard/images/Wire ties.png'
import snapRing from '../ProductLineCard/images/snap-ring.jpeg'
import ungroundThrust from '../ProductLineCard/images/unground_thrust.jpeg'
import ungroundRadial from '../ProductLineCard/images/unground_radial.jpeg'
import closeEnd from '../ProductLineCard/images/close_end_needle_bearing.jpeg'
import sealed1600 from '../ProductLineCard/images/sealed-1600.jpeg'

export let obj = {
    "Steel Ball Bearings - Inch Series": bbImg,
    "Steel Ball Bearings - Metric Series": bbImg,
    "Pneumatic Tires and Wheels": pnematicTires,
    "Precision Flanged Bearing - Inch Series": precisionFB,
    "Precision Flanged Bearing - Metric Series": precisionFB,
    "Precision Radial Bearings - Inch Series": precisionRB,
    "Precision Radial Bearings - Metric Series": precisionRB,
    "Needle Bearings Miscellaneous Sizes - Inch Series": needleBearings,
    "Needle Bearings Miscellaneous Sizes - Metric Series": needleBearings,
    "Needle Roller Bearings Cage Assemblies - Inch Series": needleBearings,
    "Closed End Needle Bearings": closeEnd,
    "Needle Bearings": needleBearings,
    "Roller Clutch Bearings": taperImg,
    "Needle Thrust Bearings and Washers - Inch Series": needleThWImg,
    "Needle Thrust Bearings and Washers - Metric Series": needleThWImg,
    "Steel Ball Bearings": steelBallImg,
    "Sealed Series -88": doubleSealFB,
    "HW Series": hwSeries,
    "AF Series": afSeries,
    "Hex Bore AFH Series": hexBoreSeries,
    "Inch B Series": needleBearings,
    "Inch SCE Series": needleBearings,
    "Roller Clutch Bearings - Inch Series": rollerClutchBearing,
    "Roller Clutch Bearings - Metric Series": rollerClutchBearing,
    "Bronze Sleeve Bushings - Inch Series": inchBronzeSleeve,
    "Bronze Sleeve Bushings - Metric Series": metricBronzeSleeve,
    "Bronze Flange Sleeve Bushing - Inch Series": inchBronzeFlange,
    "Bronze Flange Bushings - Metric Series": inchBronzeFlange,
    "Bronze Thrust Washers": inchBronzeThrustWasher,
    "Single Split": singleShiftShaftCollar,
    "One Piece Set Screw": onePieceSetShaftCollar,
    "Double Split": doubleSplitShaftCollar,
    "Nitrile Oil Seals - Inch Series": nitrileOil,
    "Nitrile Oil Seals - Metric Series": nitrileOil,
    "Viton Oil Seals - Inch Series": vitonOil,
    "Viton Oil Seals - Metric Series": vitonOil,
    "4.00-6": wheel1,
    "5.70-8": wheel2,
    "280-250-4": wheel2,
    "410-350-4": wheel2,
    "480-400-8": wheel2,
    "13-500-6": wheel3,
    "15-600-6": wheel3,
    "16x6.50-8": wheel3,
    "18.5x8.50-8": wheel4,
    "18x8.50-8": wheel5,
    "410-350-6": wheel6,
    "Roller Chain": rollerChain,
    "Cable Ties": cableTies,
    "Sealed Bearings - Inch Series 1600 Series": sealed1600,
    "Snap-Ring - Inch DLG Series": snapRing,
    "Unground Thrust Bearings 600 Series": ungroundThrust,
    "Unground Bearings - Inch A Series": ungroundRadial,

}
