import React, { useEffect, useState } from 'react';
// import { loaderGif } from '../../Components/loadingPage/index'
import NavBar from '../../Components/navbar';
import Footer from '../../Components/footer/footer';
import { ballBearings, flangedBearingsDS, CommercialFB, NeedleBearings, BronzeBushings, spindle, taper, needleWasher, linearB, camFollowers, rodEnds, steelBalls, pillowBlock, vBelts, pulleys, rollerChain, gears, sprockets, shaftCoupling, locknuts, greaseFitting, keystock, oilseal, oRing, retainedRing, brassFitting, pipeFitting, airHose, vinylTubing, ductHoses, hoseCoupling, hoseClamp, alumTubing, pnematicWheels, electronicCon, wireTire, rivets, socketHead, machineScr, hexNuts, washer, sheetMetal, dowel, rollPing, cotterPin, threadlocker, nitrileGlove, gTeck, hexBolt, pnematicCaster, battery } from './plBody/items';
// import pdfFile from './eureka-bearing.pdf'
import './productCard.css'

function ProductLineCard() {
    const [isLoaded, setIsLoaded] = useState(false)
    let extended = true
    const productLine = [ballBearings, spindle, CommercialFB, flangedBearingsDS, taper, NeedleBearings, needleWasher, linearB, camFollowers, rodEnds, steelBalls, pillowBlock, BronzeBushings, vBelts, pulleys, rollerChain, sprockets, gears,  shaftCoupling, locknuts, greaseFitting, keystock, oilseal, oRing, retainedRing, brassFitting, pipeFitting, airHose, vinylTubing, ductHoses, hoseCoupling, hoseClamp, alumTubing, pnematicWheels, pnematicCaster, electronicCon, wireTire, rivets, hexBolt, socketHead, machineScr, hexNuts, washer, sheetMetal, dowel, rollPing, cotterPin, threadlocker, nitrileGlove, gTeck, battery]
    const pdfFile = 'https://eurekaplc.s3.amazonaws.com/eureka-bearing.pdf'

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true)
        }, 1000)
    }, []);

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return isLoaded && (
        <>
                        <NavBar />
                        <div className='pl'>
                        <div className='pl-container'>
                            {/* <PlHeader /> */}
                            <div className='pl-body'>
                                <div className='pl-header-container'> 
                                    <h2 className='pl-header-title'> Product Line Card</h2>
                                    <a href={pdfFile} download target="_blank" className='pl-header-button'>
                                        <button className='pl-button'> 
                                            Download as PDF 
                                            <svg className='download-svg' xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                                <path d="M6.5 9.75L2.4375 5.6875L3.575 4.50938L5.6875 6.62187V0H7.3125V6.62187L9.425 4.50938L10.5625 5.6875L6.5 9.75ZM0 13V8.9375H1.625V11.375H11.375V8.9375H13V13H0Z" fill="#3D4647"/>
                                            </svg>
                                        </button>
                                    </a>
                                </div>
                                <div className='pl-item-container'>
                                    {productLine.map((product, index) => (
                                        <div className="pl-item" key={index}>
                                            {product}
                                        </div>
                                    ))}
                                </div>
                                    <div className='scroll-top' onClick={scrollToTop}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M1.5 12L9 4.5L16.5 12L15.1687 13.3312L9 7.1625L2.83125 13.3312L1.5 12Z" fill="black"/>
                                        </svg>
                                        Back to Top
                                    </div>
                                    
                            </div>
                        </div>
                        </div>
                        <Footer extended={extended} />
        </>
    );
}

export default ProductLineCard;
