import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import NavBar from '../../Components/navbar';
// import { loaderGif } from '../../Components/loadingPage/index'
import Footer from '../../Components/footer/footer';
import './notFound.css'
import img from './img.jpg'

function ItemNotFoundPage({ item }) {
    const [isLoaded, setIsLoaded] = useState(false)
    const location = useLocation();

    let text

    if (location.state) text = location.state.message
    if (item !== undefined) text = item


    useEffect(() => {
        (setTimeout(() => {
            setIsLoaded(true)
        }, 1000))
    }, []);

    return isLoaded && (
        <>
                    <div className='background-image'>
                        <NavBar />
                        <div className='navbar-spacing'>
                            <div className='not-found-page-container'>
                                <div className='not-found-page-header-container'>
                                    <div className='not-found-page-header'> Hmmm. We couldn't find any matches for "{text}"</div>
                                    <div className='not-found-page-text'>Double check the spelling. If you still don’t see what you’re looking for we might be able to track down the items you need. Please <NavLink to='/contact' exact={true} style={{ textDecoration: 'none', color: '#1380E4' }} > contact us </NavLink> and we can assist you!</div>
                                </div>
                                <div className='not-found-button-container'>
                                    <NavLink to='/'>
                                        <button className='not-found-brown-button'> Back to Home</button>
                                    </NavLink>
                                    <NavLink to='/contact'>
                                        <button className='not-found-white-button'> Contact </button>
                                    </NavLink>
                                </div>
                                <div> <img src={img} className='not-found-image' /> </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
        </>
    )
}

export default ItemNotFoundPage;
