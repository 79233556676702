import React, { useEffect, useState } from 'react';
import NavBar from '../../Components/navbar';
// import { loaderGif } from '../../Components/loadingPage/index'
import Footer from '../../Components/footer/footer';
import { contactHeader } from '../../Components/breadcrumb';
import './contact.css'
import building from '../../Images/EUREKA-PHOTO.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeCircleCheck, faLocationDot } from '@fortawesome/free-solid-svg-icons';


function Contact() {
    const [isLoaded, setIsLoaded] = useState(false)
    let extended = true

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true)
        }, 1000)
    }, []);


    const sendEmail = () => {
        const email = 'Sales@eurekabearing.com';
        const subject = 'Order Information';
        const body = 'Hi, I wanted to reach out to you about...';

        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };

    return isLoaded && (
        <>
                    <NavBar />
                    <div className='contact'>
                    <div className='contact-container'>
                        <div className='breadcrumb'>
                            {contactHeader}
                        </div>
                        <div className='body-container'>
                            <div className='header-container'>
                                <div className='header'> Contact Us </div>
                                <div className='header2'> Monday - Friday 8:00-4:30pm PST </div>
                            </div>    
                            <div className='numbers'>
                                <div className='numbers-left'>
                                    <div> Phone </div>
                                    <div> Toll Free </div>
                                    <div> Fax </div>
                                </div>
                                <div className='numbers-right'>
                                    <div>(714)-895-3555</div>
                                    <div>(800)-535-2888</div>
                                    <div>(714)-895-1266</div>
                                </div>
                            </div>
                            <div className='email-container'>
                                <button className='button' onClick={sendEmail}> 
                                    Email 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <path d="M11.3748 16.6251C11.3748 13.7288 13.7285 11.3751 16.6248 11.3751C17.5873 11.3751 18.4798 11.6376 19.2498 12.0838V5.25007C19.2498 4.78594 19.0654 4.34082 18.7372 4.01263C18.409 3.68444 17.9639 3.50007 17.4998 3.50007H3.49976C2.52851 3.50007 1.74976 4.27882 1.74976 5.25007V15.7501C1.74976 16.2142 1.93413 16.6593 2.26232 16.9875C2.59051 17.3157 3.03563 17.5001 3.49976 17.5001H11.4535C11.4098 17.2113 11.3748 16.9226 11.3748 16.6251ZM3.49976 7.00007V5.25007L10.4998 9.62507L17.4998 5.25007V7.00007L10.4998 11.3751L3.49976 7.00007ZM17.4998 19.2501V17.5001H13.9998V15.7501H17.4998V14.0001L20.1248 16.6251L17.4998 19.2501Z" fill="white"/>
                                    </svg>    
                                </button>
                                <div className='email'> Sales@eurekabearing.com </div>
                            </div>
                            <div className='dash'> </div>
                            <div className='location-container'>
                                <div className='location'> Location </div>
                                <div className='address-container'> 
                                    <svg className='contact-svg' xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <path d="M9.49923 9.49967C9.93464 9.49967 10.3075 9.34451 10.6179 9.03417C10.9282 8.72384 11.0831 8.35123 11.0826 7.91634C11.0826 7.48092 10.9274 7.10805 10.6171 6.79772C10.3067 6.48738 9.93412 6.33248 9.49923 6.33301C9.06381 6.33301 8.69094 6.48817 8.3806 6.79851C8.07027 7.10884 7.91537 7.48145 7.91589 7.91634C7.91589 8.35176 8.07106 8.72463 8.38139 9.03497C8.69173 9.3453 9.06434 9.5002 9.49923 9.49967ZM9.49923 15.3184C11.1089 13.8406 12.303 12.498 13.0815 11.2904C13.86 10.0829 14.2492 9.01095 14.2492 8.07467C14.2492 6.63648 13.7906 5.45901 12.8733 4.54226C11.956 3.62551 10.8313 3.16687 9.49923 3.16634C8.16659 3.16634 7.04163 3.62498 6.12435 4.54226C5.20707 5.45954 4.7487 6.63701 4.74923 8.07467C4.74923 9.01148 5.13846 10.0837 5.91694 11.2912C6.69541 12.4988 7.8895 13.8412 9.49923 15.3184ZM9.49923 17.1195C9.39367 17.1195 9.28812 17.0997 9.18256 17.0601C9.077 17.0205 8.98464 16.9677 8.90548 16.9018C6.97909 15.1997 5.54089 13.6195 4.59089 12.1613C3.64089 10.703 3.16589 9.34081 3.16589 8.07467C3.16589 6.09551 3.80266 4.51877 5.07619 3.34447C6.34971 2.17016 7.82406 1.58301 9.49923 1.58301C11.1749 1.58301 12.6495 2.17016 13.9231 3.34447C15.1966 4.51877 15.8331 6.09551 15.8326 8.07467C15.8326 9.34134 15.3576 10.7038 14.4076 12.162C13.4576 13.6203 12.0194 15.2002 10.093 16.9018C10.0138 16.9677 9.92145 17.0205 9.81589 17.0601C9.71034 17.0997 9.60478 17.1195 9.49923 17.1195Z" fill="black"/>
                                    </svg>
                                    <span className='address'>5272 System Drive Huntington Beach, CA 92649</span>
                                </div>
                            </div>       
                        </div>    
                    </div>
                    </div>
                    <div className='image-container'>
                        <img className='about-image' src={building} />    
                    </div>
                    <Footer extended={extended}/>
        </>

    )
}

export default Contact;
