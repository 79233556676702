import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './catDropDown.css'
import { rightCaret, x } from '../../Images/svg';


const CatDropDown = ({ allProducts, setOpenMenu }) => {

    let itemCategory = Array.from(new Set(allProducts.map((item) => item["Category"].S)));

    let pageFunc = (itemCat) => {
        let catFilter = allProducts.filter((item) => item.Category.S === itemCat)
        let subCats = Array.from(new Set(catFilter.map((item) => item["Sub-Category"].S)));

        if (subCats.length <= 1) {
            return `/Product-Line-Card/${itemCat}/${subCats}`
        }
        return `/Product-Line-Card/${itemCat}`
    }

    return (
        <div className='cat-dropdown'>
            <div className='cat-dropdown-container'>
                <div className='cat-dropdown-header'>
                    <div className='cat-dropdown-header-left'>
                        {rightCaret}
                        <p className='cat-dropdown-header-left-text'> Categories </p>
                    </div>
                    {x}
                </div>
                <div className='cat-dropdown-body'>
                    {[
                        { category: "Pneumatic Tires & Wheels", itemCategory: [itemCategory[1]] },
                        { category: "Flange Bearings", itemCategory: itemCategory.slice(2, 6) },
                        { category: "Bearings", itemCategory: itemCategory.slice(6, 10) },
                        { category: "Needle Bearings", itemCategory: itemCategory.slice(10, 16) },
                        { category: "Bushings and Seals", itemCategory: itemCategory.slice(16, 18) },
                        { category: "General Items", itemCategory: itemCategory.slice(18) },
                    ].map((item, index) => (
                        <div className="cat-dropdown-col" key={index}>
                            <div className="cat-dropdown-category-header">{item.category}</div>
                                {item.itemCategory.map((cats, subIndex) => (
                                    <NavLink key={subIndex} to={pageFunc(cats)} style={{ textDecoration: 'none' }} onClick={() => { if(setOpenMenu)setOpenMenu(false)}}>
                                        <div className="cat-dropdown-category-text" key={subIndex}>{cats}</div>
                                    </NavLink>
                                ))}
                        </div>
                    ))}               
                </div>
            </div>
        </div>
    );
}

export default CatDropDown;
