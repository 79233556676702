import { useState } from "react"
import { useNavigate } from 'react-router-dom'
import './searchbar.css'
import { searchIcon } from "../../Images/svg"

const SearchBar = ({ allProducts }) => {
    const navigate = useNavigate();
    const [filterPart, setFilterPart] = useState([])
    const [search, setSearch] = useState('')


    const handleSearch = (e) => {
        e.preventDefault();
        const partSearch = e.target.value;
        setSearch(partSearch);

        const searchProperties = ['Part Number', 'General', 'Schatz', 'Lutco', 'Heim/RBC', 'Kilian', 'NCTe', 'Nice', 'Torrington', 'INA', 'IKO', 'NTN', 'Reliable/IsostatTC', 'Symmco', 'Category', "Sub-Category"];

        const findPart = allProducts?.filter((product) => {
            for (let i = 0; i < searchProperties.length; i++) {
                const property = searchProperties[i];
                const value = product[property];
                if (value && value.S && value.S.toLowerCase().includes(partSearch.toLowerCase())) {
                    return true;
                }
            }
            return false;
        });
        setFilterPart(findPart)
    };
    
    
    const handleSubmit = (e) => {
        e.preventDefault()

        if (filterPart.length) {
            let part = filterPart[0]
            const category = part['Category'].S;
            const subcategory = part['Sub-Category'].S;
            const item = part['Part Number'].S;
            let url = '';

            let singles = ["Unground Bearing", 'Unground Thrust Bearings 600 Series', 'Snap-Ring - Inch DLG Series', 'Sealed Bearings - Inch 1600 Series', 'Closed End Needle Bearings', 'Needle Roller Bearings Cage Assemblies', 'Roller Chain', 'Cable Ties']

            if (search.toLowerCase() === item.toLowerCase()) {
                url = `/Product-Line-Card/${category}/${subcategory}/${encodeURIComponent(item)}`;
            } else if (search === subcategory) {
                url = `/Product-Line-Card/${category}/${subcategory}`;
            } else {
                if (singles.includes(category)) {
                    url = `/Product-Line-Card/${category}/${subcategory}`;
                } else {
                    url = `/Product-Line-Card/${category}`;
                }
            }

            navigate(url, { replace: true })
        } else {
            navigate('/item-not-found', { state: { message: search } })
        }

        setFilterPart([])
        setSearch('')
    }

    const clearSearch = () => {
        setFilterPart([])
        setSearch('')
    }

    return (
        <div className='search-container'>
            <form className="search-input-container"
                onSubmit={handleSubmit}>
                <input
                    className='search-input'
                    type='text'
                    value={search}
                    onChange={handleSearch}
                    placeholder='Search part number'
                />
            </form>
            <svg className="search-svg" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none" onClick={handleSubmit}>
                <path d="M21.9583 19.8333H20.8392L20.4425 19.4508C21.8788 17.7849 22.6682 15.658 22.6667 13.4583C22.6667 11.6371 22.1266 9.85676 21.1148 8.34246C20.103 6.82816 18.6648 5.6479 16.9822 4.95095C15.2996 4.25399 13.4481 4.07163 11.6619 4.42694C9.87564 4.78225 8.23487 5.65925 6.94706 6.94706C5.65925 8.23487 4.78225 9.87564 4.42694 11.6619C4.07163 13.4481 4.25399 15.2996 4.95095 16.9822C5.6479 18.6648 6.82816 20.103 8.34246 21.1148C9.85676 22.1266 11.6371 22.6667 13.4583 22.6667C15.7392 22.6667 17.8358 21.8308 19.4508 20.4425L19.8333 20.8392V21.9583L26.9167 29.0275L29.0275 26.9167L21.9583 19.8333ZM13.4583 19.8333C9.93084 19.8333 7.08334 16.9858 7.08334 13.4583C7.08334 9.93084 9.93084 7.08334 13.4583 7.08334C16.9858 7.08334 19.8333 9.93084 19.8333 13.4583C19.8333 16.9858 16.9858 19.8333 13.4583 19.8333Z"/>
            </svg>
        </div >
    )
}

export default SearchBar
