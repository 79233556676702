import { NavLink } from 'react-router-dom';
import bbImg from '../images/ballbearing.png'
import spindleImg from '../images/Spindle.png'
import pnematicTires from '../images/pneumatic caster.png'
import commercialFB from '../images/commercial-flanged-bearing 1.png'
import precisionFB from '../images/image 4.png'
import precisionRB from '../images/ballbearing.png'
import needleBearings from '../images/needle bearing.png'
import bronzeBushings from '../images/bronze bushings.png'
import dsfb from '../images/double-seal-flanged 1.png'
import taperImg from '../images/taper roller.png'
import needleThWImg from '../images/needle-thrust.png'
import lbImg from '../images/Linear bearing.png'
import camFolImg from '../images/cam follower.png'
import rodEndsImg from '../images/rod end.png'
import steelBallImg from '../images/steel balls.png'
import pillowBlockImg from '../images/Pillow Blocks.png'
import vbeltsImg from '../images/v-belt.png'
import pulleysImg from '../images/Pulleyes.png'
import rollerChainImg from '../images/roller chain.png'
import sprocketsImg from '../images/sprocket.png'
import gearsImg from '../images/gears.png'
import shaftCouplingsImg from '../images/shaft-coupling.png'
import locknutsImg from '../images/locknut-lockwasher.png'
import greaseImg from '../images/grease fitting.png'
import keystocksImg from '../images/keystock.png'
import oilSealsImg from '../images/oil-seals.png'
import oRingsImg from '../images/O-Rings.png'
import retRingsImg from '../images/Retaining Ring.png'
import brassFitImg from '../images/brass fitting.png'
import pipeFitImg from '../images/pipe fitting.png'
import airHoImg from '../images/air-hose.png'
import vinylTubImg from '../images/Vinyl Tubing.png'
import ductHoImg from '../images/duct hose.png'
import hoseCoupImg from '../images/hose coupling.png'
import hoseClampImg from '../images/Hose Clamp.png'
import alumTubImg from '../images/aluminum-tubing.png'
import pnematicWhImg from '../images/pneumatic-wheel.png'
import pnematicCastImg from '../images/pneumatic caster.png'
import electronicConImg from '../images/Electronic Connectors.png'
import wireTireImg from '../images/Wire ties.png'
import rivetsImg from '../images/Rivets.png'
import hexBoltImg from '../images/hex-bolt.png'
import socketHeadImg from '../images/sock head.png'
import machineScrImg from '../images/Machine screws.png'
import hexNutsImg from '../images/Hex Nuts.png'
import washerImg from '../images/Washers.png'
import sheetMetalImg from '../images/sheet metal screw.png'
import dowelImg from '../images/dowel pin.png'
import rollPingImg from '../images/roll-pin.png'
import cotterPinImg from '../images/cotter-pin.png'
import threadlockerImg from '../images/threadlocker.png'
import nitrileGloveImg from '../images/nitrile-glove.png'
import gTeckImg from '../images/G-TEK glove.png'
import batteryImg from '../images/Battery.png'
import './items.css'



export const ballBearings = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='bbImg' src={bbImg} />
        </div>
        <div className='pl-item-text'>
            Ball Bearings
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const spindle = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='spindleImg' src={spindleImg} />
        </div>
        <div className='pl-item-text'>
            Spindle Bearings
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const flangedBearingsDS = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='dsfb' src={dsfb} />
        </div>
        <div className='pl-item-text'>
            Double Sealed Flanged Bearings
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const taper = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='taperImg' src={taperImg} />
        </div>
        <div className='pl-item-text'>
            Taper Roller Bearings
        </div>
        <div className='pl-text-underline'></div>
    </>
)


export const needleWasher = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='needle Thrust' src={needleThWImg} />
        </div>
        <div className='pl-item-text'>
            Needle Thrust Bearings / Washer
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const linearB = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Linear bearings' src={lbImg} />
        </div>
        <div className='pl-item-text'>
            Linear Bearings
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const taperRB = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Taper roller bearings' src={bbImg} />
        </div>
        <div className='pl-item-text'>
            Taper Roller Bearings
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const pnematic = (
    <>
        <div className='category-item-container'>
            <div className='pl-image-container'>
                <img className='pl-image' alt=' Pnematic Tires and Wheel' src={pnematicTires} />
            </div>
            <div className='pl-item-text'>
                Pnematic Tires and Wheel
            </div>
            <div className='pl-text-underline'></div>

        </div>
    </>
)


export const CommercialFB = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Commerical Flange Bearings' src={commercialFB} />
        </div>
        <div className='pl-item-text'>
            Commerical Flange Bearings
        </div>
        <div className='pl-text-underline'></div>
    </>
)


export const PrecisionFB = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Precision Flange Bearings' src={precisionFB} />
        </div>
        <div className='pl-item-text'>
            Precision Flange Bearings
        </div>
        <div className='pl-text-underline'></div>
    </>
)


export const PrecisionRB = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt=' Precision Radial Bearings' src={precisionRB} />
        </div>
        <div className='pl-item-text'>
            Precision Radial Bearings
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const NeedleBearings = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt=' Needle Bearings' src={needleBearings} />
        </div>
        <div className='pl-item-text'>
            Needle Bearings
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const BronzeBushings = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Bronze Bushings' src={bronzeBushings} />
        </div>
        <div className='pl-item-text'>
            Bronze Bushings
        </div>
        <div className='pl-text-underline'></div>
    </>
)


export const camFollowers = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Cam Followers' src={camFolImg} />
        </div>
        <div className='pl-item-text'>
            Cam Followers
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const rodEnds = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Rod Ends' src={rodEndsImg} />
        </div>
        <div className='pl-item-text'>
            Rod Ends
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const steelBalls = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Steel Balls' src={steelBallImg} />
        </div>
        <div className='pl-item-text'>
            Steel Balls
        </div>
        <div className='pl-text-underline'></div>
    </>
)
export const pillowBlock = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Pillow Blocks' src={pillowBlockImg} />
        </div>
        <div className='pl-item-text'>
            Pillow Blocks
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const vBelts = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt=' V-Belts' src={vbeltsImg} />
        </div>
        <div className='pl-item-text'>
            V-Belts
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const pulleys = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt=' Pulleys' src={pulleysImg} />
        </div>
        <div className='pl-item-text'>
            Pulleys
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const rollerChain = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Roller Chains' src={rollerChainImg} />
        </div>
        <div className='pl-item-text'>
            Roller Chains
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const sprockets = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Sprockets' src={sprocketsImg} />
        </div>
        <div className='pl-item-text'>
            Sprockets
        </div>
        <div className='pl-text-underline'></div>
    </>
)


export const gears = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Gears' src={gearsImg} />
        </div>
        <div className='pl-item-text'>
            Gears
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const shaftCoupling = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Shaft Couplings' src={shaftCouplingsImg} />
        </div>
        <div className='pl-item-text'>
            Shaft Couplings
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const locknuts = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Locknuts / Lockwashers' src={locknutsImg} />
        </div>
        <div className='pl-item-text'>
            Locknuts / Lockwashers
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const greaseFitting = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Grease Fittings' src={greaseImg} />
        </div>
        <div className='pl-item-text'>
            Grease Fittings
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const keystock = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='KeyStocks' src={keystocksImg} />
        </div>
        <div className='pl-item-text'>
            KeyStocks
        </div>
        <div className='pl-text-underline'></div>
    </>
)


export const oilseal = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt=' Oil Seals' src={oilSealsImg} />
        </div>
        <div className='pl-item-text'>
            Oil Seals
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const oRing = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='O-Rings' src={oRingsImg} />
        </div>
        <div className='pl-item-text'>
            O-Rings
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const retainedRing = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Retaining Rings' src={retRingsImg} />
        </div>
        <div className='pl-item-text'>
            Retaining Rings
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const brassFitting = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Brass Fittings' src={brassFitImg} />
        </div>
        <div className='pl-item-text'>
            Brass Fittings
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const pipeFitting = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Pipe Fittings' src={pipeFitImg} />
        </div>
        <div className='pl-item-text'>
            Pipe Fittings
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const airHose = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Air Hoses' src={airHoImg} />
        </div>
        <div className='pl-item-text'>
            Air Hoses
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const vinylTubing = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Vinyl Tubing' src={vinylTubImg} />
        </div>
        <div className='pl-item-text'>
            Vinyl Tubing
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const ductHoses = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Duct Hoses' src={ductHoImg} />
        </div>
        <div className='pl-item-text'>
            Duct Hoses
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const hoseCoupling = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Hose Couplings' src={hoseCoupImg} />
        </div>
        <div className='pl-item-text'>
            Hose Couplings
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const hoseClamp = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Hose Clamps' src={hoseClampImg} />
        </div>
        <div className='pl-item-text'>
            Hose Clamps
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const alumTubing = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt=' Aluminum Tubing' src={alumTubImg} />
        </div>
        <div className='pl-item-text'>
            Aluminum Tubing
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const pnematicWheels = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Pneumatic Wheels' src={pnematicWhImg} />
        </div>
        <div className='pl-item-text'>
            Pneumatic Wheels
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const pnematicCaster = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Pnematic Casters' src={pnematicCastImg} />
        </div>
        <div className='pl-item-text'>
            Pnematic Casters
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const electronicCon = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Electronic Connectors' src={electronicConImg} />
        </div>
        <div className='pl-item-text'>
            Electronic Connectors
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const wireTire = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Wire Ties' src={wireTireImg} />
        </div>
        <div className='pl-item-text'>
            Wire Ties
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const rivets = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Rivets' src={rivetsImg} />
        </div>
        <div className='pl-item-text'>
            Rivets
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const hexBolt = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt=' Hex Bolts' src={hexBoltImg} />
        </div>
        <div className='pl-item-text'>
            Hex Bolts
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const socketHead = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Socket Head Capscrews' src={socketHeadImg} />
        </div>
        <div className='pl-item-text'>
            Socket Head Capscrews
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const machineScr = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Machine Screws' src={machineScrImg} />
        </div>
        <div className='pl-item-text'>
            Machine Screws
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const hexNuts = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Hex Buts' src={hexNutsImg} />
        </div>
        <div className='pl-item-text'>
            Hex Buts
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const washer = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Washers' src={washerImg} />
        </div>
        <div className='pl-item-text'>
            Washers
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const sheetMetal = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Sheet Metal Screws' src={sheetMetalImg} />
        </div>
        <div className='pl-item-text'>
            Sheet Metal Screws
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const dowel = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt=' Dowel Pins' src={dowelImg} />
        </div>
        <div className='pl-item-text'>
            Dowel Pins
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const rollPing = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Roll Pins' src={rollPingImg} />
        </div>
        <div className='pl-item-text'>
            Roll Pins
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const cotterPin = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Cotter Pins' src={cotterPinImg} />
        </div>
        <div className='pl-item-text'>
            Cotter Pins
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const threadlocker = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Threadlocker' src={threadlockerImg} />
        </div>
        <div className='pl-item-text'>
            Threadlocker
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const nitrileGlove = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt=' Nitrile Gloves' src={nitrileGloveImg} />
        </div>
        <div className='pl-item-text'>
            Nitrile Gloves
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const gTeck = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='G-TEK Gloves' src={gTeckImg} />
        </div>
        <div className='pl-item-text'>
            G-TEK Gloves
        </div>
        <div className='pl-text-underline'></div>
    </>
)

export const battery = (
    <>
        <div className='pl-image-container'>
            <img className='pl-image' alt='Batteries' src={batteryImg} />
        </div>
        <div className='pl-item-text'>
            Batteries
        </div>
        <div className='pl-text-underline'></div>
    </>
)
