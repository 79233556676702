//build the hero bearing model
import Spline from '@splinetool/react-spline';
import './spline.css'




function BearingModel() {
    try {
        return (
          <div className='bearingWrapper'>
            <Spline scene="https://prod.spline.design/bh0klvydwqDGIffH/scene.splinecode"/>
          </div>
        );
      } catch (error) {
        console.error(error);
        return <div>Error: Failed to load Spline scene.</div>;
      }
}

export default BearingModel
