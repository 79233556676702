import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import NavBar from '../../Components/navbar';
// import { loaderGif } from '../../Components/loadingPage/index'
import { ItemHeader } from '../../Components/breadcrumb';
import { getAllThunk } from '../../store/products';
import ItemBody from './body';
import ItemNotFoundPage from '../notFound';
import Footer from '../../Components/footer/footer';
import './itemPage.css'


function ItemPage() {
    const dispatch = useDispatch()
    const [isLoaded, setIsLoaded] = useState(false)
    const [isValid, setIsValid] = useState(false);
    const allProducts = useSelector(state => Object.values(state.products))
    let { category, subcategory, item } = useParams()
    let extended = true

    useEffect(() => {
        dispatch(getAllThunk())
            .then(() => {
                setIsLoaded(true);
            });
    }, [dispatch]);


    useEffect(() => {
        if (Object.keys(allProducts).length === 0) {
            dispatch(getAllThunk());
        }
    }, [dispatch, allProducts]);


    const referenceKeys = ['General', 'Schatz', 'Lutco', 'Heim/RBC', 'Kilian', 'NCTe', 'Nice', 'Torrington', 'INA', 'IKO', 'NTN', 'Reliable/IsostatTC', 'Symmco']
    const productKeys = ['Bearing Type', 'Brand', 'Breaking Load', 'Clamp Screw', 'Color', 'Decimal', 'Description', 'Flange Length', 'Flange O.D.', 'Flange Thickness', 'I.D.', 'I.R.W', 'Length', 'Lip CODE', 'Material', 'O.D.', 'O.R.W', 'Pack', 'Part Number', 'Pin Diameter', 'Pin Length', 'Pitch', 'Rim Size', 'Roller Diameter', 'Set Screw', 'Strength', 'Thickness', 'Tire Number', 'Width']
    const subCategoryFilter = allProducts.filter((item) => item['Sub-Category'].S === subcategory);
    let part = Array.from(new Set(allProducts.filter((product) => product['Part Number'].S === item && product['Sub-Category'].S === subcategory)));


    let breadcrumbSet = new Set();
    let breadcrumb = [];
    subCategoryFilter.forEach((item) => {
        const array = [item["Image"].S, item['Sub-Category'].S];
        if (!breadcrumbSet.has(JSON.stringify(array))) {
            breadcrumbSet.add(JSON.stringify(array));
            breadcrumb.push(array);
        }
    });

    const referenceNumbers = [];
    const productDetails = [];


    part.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
            if (referenceKeys.includes(key) && value.S.length > 0) {
                const formattedValue = value.S.includes("\\") ? value.S.replace("\\", "") : value.S;
                referenceNumbers.push({ [key]: formattedValue });
            } else if (productKeys.includes(key) && value.S.length > 0) {
                const formattedValue = value.S.includes("\r") ? value.S.replace("\r", "") : value.S;
                if (formattedValue.length > 0) { 
                    productDetails.push({ [key]: formattedValue });
                }
            }
        });
    });

    const sortedReferenceData = referenceNumbers.sort((a, b) => {
        const keyA = Object.keys(a)[0];
        const keyB = Object.keys(b)[0];
        return keyA.localeCompare(keyB);
    });

    const sortedProductData = productDetails.sort((a, b) => {
        const keyA = Object.keys(a)[0];
        const keyB = Object.keys(b)[0];
        return keyA.localeCompare(keyB);
    });


    return isLoaded && (
        <>
                    <div className='background-image'>
                        <NavBar />
                        <div className='item'>
                            <div className='item-container'>
                                <div className='breadcrumb'>
                                    <ItemHeader category={category} subCategory={breadcrumb} partNumber={item} allProducts={allProducts} />
                                </div>
                                <div className='item-header-container'>
                                    <div className='item-title'>{item}</div>
                                    <div className='item-header-two'> {part[0]?.Subheader.S}</div>
                                </div>
                                <div className='item-body-container'>
                                    <ItemBody sortedReferenceData={sortedReferenceData} sortedProductData={sortedProductData} subCategory={subcategory} />
                                </div>
                            </div>
                        </div>
                        <Footer extended={extended}/>
                    </div>
        </>
    )
}

export default ItemPage;
