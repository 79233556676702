
export let searchIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
      <path d="M21.9583 19.8333H20.8392L20.4425 19.4508C21.8788 17.7849 22.6682 15.658 22.6667 13.4583C22.6667 11.6371 22.1266 9.85676 21.1148 8.34246C20.103 6.82816 18.6648 5.6479 16.9822 4.95095C15.2996 4.25399 13.4481 4.07163 11.6619 4.42694C9.87564 4.78225 8.23487 5.65925 6.94706 6.94706C5.65925 8.23487 4.78225 9.87564 4.42694 11.6619C4.07163 13.4481 4.25399 15.2996 4.95095 16.9822C5.6479 18.6648 6.82816 20.103 8.34246 21.1148C9.85676 22.1266 11.6371 22.6667 13.4583 22.6667C15.7392 22.6667 17.8358 21.8308 19.4508 20.4425L19.8333 20.8392V21.9583L26.9167 29.0275L29.0275 26.9167L21.9583 19.8333ZM13.4583 19.8333C9.93084 19.8333 7.08334 16.9858 7.08334 13.4583C7.08334 9.93084 9.93084 7.08334 13.4583 7.08334C16.9858 7.08334 19.8333 9.93084 19.8333 13.4583C19.8333 16.9858 16.9858 19.8333 13.4583 19.8333Z" fill="#674736"/>
    </svg>
)
  
export let menuBar = (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
    <path d="M4.25 5.66666H29.75V8.49999H4.25V5.66666ZM12.75 15.5833H29.75V18.4167H12.75V15.5833ZM4.25 25.5H29.75V28.3333H4.25V25.5Z" fill="#734D39"/>
  </svg>
)

export let leftCaret = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M5.33333 1.33333L12 8L5.33333 14.6667L4.15 13.4833L9.63333 8L4.15 2.51666L5.33333 1.33333Z" fill="#242B32"/>
  </svg>
)

export let rightCaret = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="30" viewBox="0 0 18 30" fill="none">
  <path d="M14.6667 29.1667L0.5 15L14.6667 0.833313L17.1812 3.3479L5.52917 15L17.1812 26.6521L14.6667 29.1667Z" fill="#674736"/>
  </svg>
)

export let x = (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
    <path d="M2.56667 25.6667L0 23.1L10.2667 12.8333L0 2.56667L2.56667 0L12.8333 10.2667L23.1 0L25.6667 2.56667L15.4 12.8333L25.6667 23.1L23.1 25.6667L12.8333 15.4L2.56667 25.6667Z" fill="#674736"/>
  </svg>
)

export let rightArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path d="M10.3903 3.72081L15.6153 8.94581C15.6945 9.02497 15.7507 9.11074 15.7839 9.2031C15.8172 9.29546 15.8335 9.39442 15.833 9.49997C15.833 9.60553 15.8164 9.70449 15.7831 9.79685C15.7499 9.88921 15.6939 9.97497 15.6153 10.0541L10.3903 15.2791C10.2451 15.4243 10.0636 15.5 9.84562 15.5063C9.62765 15.5127 9.43976 15.4369 9.28195 15.2791C9.12362 15.134 9.04102 14.9524 9.03416 14.7345C9.0273 14.5165 9.1033 14.3286 9.26216 14.1708L13.1413 10.2916L4.29445 10.2916C4.07015 10.2916 3.882 10.2156 3.73 10.0636C3.578 9.91164 3.50226 9.72375 3.50279 9.49997C3.50279 9.27567 3.57852 9.08751 3.73 8.93551C3.88147 8.78351 4.06962 8.70778 4.29445 8.7083L13.1413 8.70831L9.26216 4.82914C9.11702 4.684 9.04102 4.49928 9.03416 4.27497C9.0273 4.05067 9.1033 3.86594 9.26216 3.72081C9.4073 3.56247 9.59202 3.48331 9.81633 3.48331C10.0406 3.48331 10.232 3.56247 10.3903 3.72081Z" fill="#242B32"/>
  </svg>
)
