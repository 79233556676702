import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, NavLink } from 'react-router-dom';
import { getAllThunk } from '../../store/products';
// import { loaderGif } from '../../Components/loadingPage/index'
import { obj } from './imageObj.js'
import { CategoryHeader } from '../../Components/breadcrumb';
import NavBar from '../../Components/navbar';
import Footer from '../../Components/footer/footer';
import './Category.css'


function Categories() {
    const dispatch = useDispatch();
    const [isValid, setIsValid] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const allProducts = useSelector(state => Object.values(state.products));
    let { category } = useParams();
    let imgUrl = obj[category]
    let extended = true

    useEffect(() => {
        dispatch(getAllThunk())
            .then(() => {
                setTimeout(() => {
                    setIsLoaded(true);
                }, 500);
            });
    }, [dispatch]);

    let catFilter = allProducts.filter((item) => item.Category.S === category);

    let subCats = Array.from(
        new Set(catFilter.map(item => [item["Image"].S, item["Sub-Category"].S].join(',')))
    ).map(str => str.split(','))
    // .filter(arr => arr[0] !== "Needle Bearings Miscellaneous Sizes");


    useEffect(() => {
        const catArray = Array.from(new Set(allProducts.map((item) => item.Category.S)))
        if (!catArray.includes(category)) {
            setIsValid(false);
        }
    }, [category]);

    // console.log(subCats)

    return isLoaded && (
        <>
                        <NavBar />
                        <div className='category'>
                            <div className='category-container'>
                                <div className='breadcrumb'>
                                    <CategoryHeader category={category} />
                                </div>
                                <div className='category-header'>{category}</div>
                                <div className='category-body-container'>
                                    {subCats.map((cats) => (
                                            <div className='category-item'>
                                                <NavLink to={`/Product-Line-Card/${category}/${cats[1]}`} style={{ textDecoration: 'none', color: '#242B32' }}>
                                                    <div className='category-image-container'>
                                                    <img className='category-image' src={obj[cats[1]]} alt={obj[cats[1]]}/>
                                                    </div>
                                                    <div className='category-item-text'>
                                                        <div className='category-text'>{cats[0].length > 0 ? cats[0] : cats[1]}</div>
                                                        <svg className='category-svg' xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                            <path d="M10.3903 3.72081L15.6153 8.94581C15.6945 9.02497 15.7507 9.11074 15.7839 9.2031C15.8172 9.29546 15.8335 9.39442 15.833 9.49997C15.833 9.60553 15.8164 9.70449 15.7831 9.79685C15.7499 9.88921 15.6939 9.97497 15.6153 10.0541L10.3903 15.2791C10.2451 15.4243 10.0636 15.5 9.84562 15.5063C9.62765 15.5127 9.43976 15.4369 9.28195 15.2791C9.12362 15.134 9.04102 14.9524 9.03416 14.7345C9.0273 14.5165 9.1033 14.3286 9.26216 14.1708L13.1413 10.2916L4.29445 10.2916C4.07015 10.2916 3.882 10.2156 3.73 10.0636C3.578 9.91164 3.50226 9.72375 3.50279 9.49997C3.50279 9.27567 3.57852 9.08751 3.73 8.93551C3.88147 8.78351 4.06962 8.70778 4.29445 8.7083L13.1413 8.70831L9.26216 4.82914C9.11702 4.684 9.04102 4.49928 9.03416 4.27497C9.0273 4.05067 9.1033 3.86594 9.26216 3.72081C9.4073 3.56247 9.59202 3.48331 9.81633 3.48331C10.0406 3.48331 10.232 3.56247 10.3903 3.72081Z" fill="#242B32"/>
                                                        </svg>
                                                    </div>
                                                </NavLink>
                                                <div className='category-text-underline'></div>
                                            </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <Footer extended={extended} />
        </>
    );
}

export default Categories;
