import React, { createContext, useState, useEffect } from 'react';

export const ScreenContext = createContext();

export const ScreenProvider = ({ children }) => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 920);

  useEffect(() => {
    const handleResize = () => {

      const size = window.innerWidth;
      setIsLargeScreen(size > 920);
    };

    handleResize(); // Call the handleResize function initially to set the initial small screen state

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ScreenContext.Provider value={{ isLargeScreen }}>
      {children}
    </ScreenContext.Provider>
  );
};
