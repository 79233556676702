import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from './store';

// import config from './aws-exports';
// import { Amplify } from 'aws-amplify';
// import { AmplifyProvider } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css'
// Amplify.configure(config)

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = configureStore();

root.render(
  // <AmplifyProvider>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  // </AmplifyProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
