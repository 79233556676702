import React, { useEffect, useState, useContext, useMemo } from 'react';
import NavBar from '../../Components/navbar';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, NavLink } from 'react-router-dom';
// import { loaderGif } from '../../Components/loadingPage/index'
import { SubCategoryHeader } from '../../Components/breadcrumb';
import { getAllThunk } from '../../store/products';
import './subCategory.css'
import NotFound from '../404';
import Footer from '../../Components/footer/footer';

function SubCategoryPage() {
    const dispatch = useDispatch()
    const [isValid, setIsValid] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false)
    const allProducts = useSelector(state => Object.values(state.products))

    let { category, subcategory } = useParams()
    let extended = true

    useEffect(() => {
        dispatch(getAllThunk())
            .then(setTimeout(() => {
                setIsLoaded(true)
            }, 1000))
    }, []);

    const subCategoryFilter = allProducts.filter((item) => item['Sub-Category'].S === subcategory);
    let partNumber = subCategoryFilter.map((item) => item["Part Number"].S);
    let breadcrumb = Array.from(new Set(subCategoryFilter.map((item) => item["Image"].S.length > 0 ? item["Image"].S :  item["Sub-Category"].S)));

    return isLoaded && (
        <>
                    <div className='background-image'>
                        <NavBar />
                        <div className='sub'>
                            <div className='sub-container'>
                                <div className='breadcrumb'>
                                    < SubCategoryHeader category={category} subCategory={breadcrumb} allProducts={allProducts} />
                                </div>
                                <div className='sub-header'>
                                    <div className='title'>{breadcrumb[0]}</div>
                                    <div className='subheader-text'> Select your part number to see more details</div>
                                </div>
                                <div className='sub-subheader'>
                                    Part Number:
                                </div>
                                <div className='sub-body-container'>
                                    {partNumber.map((item, index) => (
                                            <NavLink className='sub-item' to={`/Product-Line-Card/${category}/${subcategory}/${encodeURIComponent(item)}`}>
                                                    {item}
                                            </NavLink>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <Footer extended={extended} />
                    </div>
        </>
    )
}

export default SubCategoryPage;
