import React from 'react';
// import {useSelector}
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/Homepage';
import ProductLineCard from './Pages/ProductLineCard';
import Categories from './Pages/CategoryPage';
import Contact from './Pages/Contact';
import About from './Pages/About';
import ItemPage from './Pages/ItemPage';
import SubCategoryPage from './Pages/SubCategoryPage';
import { NavProvider } from './context/navContext';
import { ScreenProvider } from './context/screenContext';
import { ScreenSizeProvider } from './context/screenSizeContext';
import NotFound from './Pages/404';
import ItemNotFoundPage from './Pages/notFound';

function App() {

  return (
    <BrowserRouter>
      <ScreenProvider>
        <ScreenSizeProvider>
          <NavProvider>
            <Routes>
              <Route exact path='/' element={<HomePage />} />
              <Route exact path='/Product-Line-Card' element={<ProductLineCard />} />
              <Route exact path='/Product-Line-Card/:category' element={<Categories />} />
              <Route exact path='/Product-Line-Card/:category/:subcategory' element={<SubCategoryPage />} />
              <Route exact path='/Product-Line-Card/:category/:subcategory/:item' element={<ItemPage />} />
              <Route exact path='/contact' element={<Contact />} />
              <Route exact path='/about' element={<About />} />
              <Route exact path='/item-not-found' element={<ItemNotFoundPage />} />
              <Route path='/*' element={<NotFound />} />
            </Routes>
          </NavProvider>
        </ScreenSizeProvider>
      </ScreenProvider>
    </BrowserRouter>
  );
}

export default App;
