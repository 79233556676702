import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getAllThunk } from '../../store/products';
import SearchBar from './searchbar.js';
import CatDropDown from './catDropDown';
import TabletNavbar from './tabletNavbar';
import { searchIcon, menuBar } from '../../Images/svg';
import { ScreenContext } from '../../context/screenContext';
import './navbar.css'

const NavBar = () => {

  const dispatch = useDispatch()
  const allProducts = useSelector(state => Object.values(state.products))
  const [openSearch, setOpenSearch] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [openCategories, setOpenCategories] = useState(false)
  const { isLargeScreen } = useContext(ScreenContext);
  const buttonRef = useRef(null);

  useEffect(() => {
      if (!openCategories) return;
      const handleOutsideClick = (event) => {
          if (buttonRef.current && !buttonRef.current.contains(event.target)) {
              setOpenCategories(false);
          }
      };
      const timer = setTimeout(() => {
          document.addEventListener('click', handleOutsideClick);
      }, 0);
  
      return () => {
          clearTimeout(timer);
          document.removeEventListener('click', handleOutsideClick);
      };
  }, [openCategories]);

  // dispatching info
  useEffect(() => {
    dispatch(getAllThunk())
  }, []);

  const initiateCall = () => {
    window.location.href = `tel:1-714-895-3555`;
  };
  
  let normalMenu = (
    <div className='normal-menu' ref={buttonRef} onClick={(e) => e.stopPropagation()}>
      <div to='/' className='normal-menu-text' id='normal-menu-cat' onClick={() => setOpenCategories(!openCategories)}>
        CATEGORIES
        <svg width="12" height="12" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transition:" transform 0.5s", transform: openCategories ? 'rotate(-180deg)' : 'none'}}>
          <path d="M10 0.904459L5 6L-1.11367e-06 0.904461L0.887499 2.54784e-06L5 4.19108L9.1125 7.50213e-07L10 0.904459Z" fill="#3D4647"/>
        </svg>

        { openCategories ? <CatDropDown allProducts={allProducts}/> : '' }
      </div>
      <NavLink to='/Product-Line-Card' className='normal-menu-text'>
          PRODUCT LINE CARD
      </NavLink>
      <NavLink to='/about' className='normal-menu-text'>
          ABOUT
      </NavLink>
      <NavLink to='/contact' className='normal-menu-text'>
          CONTACT
      </NavLink>
    </div>
  )


  return (
    <nav>
      <div className='brown-bar'>
        <div className='brown-container'>
          <div className='brown-bar-left'>
            <svg className='brown-bar-svg' width="14" height="14" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.49947 6.4998C6.79739 6.4998 7.05251 6.39363 7.26485 6.1813C7.47718 5.96896 7.58317 5.71402 7.5828 5.41646C7.5828 5.11855 7.47664 4.86342 7.2643 4.65109C7.05197 4.43875 6.79703 4.33277 6.49947 4.33313C6.20155 4.33313 5.94643 4.4393 5.7341 4.65163C5.52176 4.86396 5.41578 5.11891 5.41614 5.41646C5.41614 5.71438 5.5223 5.9695 5.73464 6.18184C5.94697 6.39417 6.20192 6.50016 6.49947 6.4998ZM6.49947 10.481C7.60086 9.46994 8.41787 8.55127 8.95051 7.72505C9.48315 6.89882 9.74947 6.16541 9.74947 5.5248C9.74947 4.54077 9.43567 3.73513 8.80805 3.10788C8.18044 2.48063 7.41092 2.16682 6.49947 2.16646C5.58767 2.16646 4.81796 2.48027 4.19035 3.10788C3.56273 3.73549 3.24911 4.54113 3.24947 5.5248C3.24947 6.16577 3.51579 6.89937 4.04843 7.72559C4.58107 8.55181 5.39808 9.4703 6.49947 10.481ZM6.49947 11.7133C6.42725 11.7133 6.35503 11.6998 6.2828 11.6727C6.21058 11.6456 6.14739 11.6095 6.09322 11.5644C4.77517 10.3998 3.79114 9.31863 3.14114 8.32088C2.49114 7.32313 2.16614 6.3911 2.16614 5.5248C2.16614 4.17063 2.60182 3.09181 3.47318 2.28834C4.34454 1.48487 5.3533 1.08313 6.49947 1.08313C7.646 1.08313 8.65494 1.48487 9.5263 2.28834C10.3977 3.09181 10.8332 4.17063 10.8328 5.5248C10.8328 6.39146 10.5078 7.32367 9.85781 8.32142C9.20781 9.31917 8.22378 10.4002 6.90572 11.5644C6.85155 11.6095 6.78836 11.6456 6.71614 11.6727C6.64392 11.6998 6.57169 11.7133 6.49947 11.7133Z" fill="white"/>
            </svg>
            <p class="brown-bar-text">5272 System Drive Huntington Beach, CA 92649</p>
          </div>
          <div className='brown-bar-right' onClick={initiateCall}>
            <svg className='brown-bar-svg' width="14" height="14" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.37083 5.07777C9.2625 4.96944 9.20833 4.8407 9.20833 4.69156C9.20833 4.54242 9.2625 4.41387 9.37083 4.3059L9.85833 3.8184H7.61042C7.45694 3.8184 7.32821 3.76658 7.22421 3.66294C7.12021 3.5593 7.06839 3.43056 7.06875 3.27673C7.06875 3.12326 7.12075 2.9947 7.22475 2.89106C7.32875 2.78742 7.45731 2.73542 7.61042 2.73506H9.85833L9.37083 2.24756C9.2625 2.13923 9.21068 2.01284 9.21538 1.8684C9.22007 1.72395 9.27189 1.59756 9.37083 1.48923C9.47917 1.3809 9.6079 1.32438 9.75704 1.31969C9.90618 1.31499 10.0347 1.36699 10.1427 1.47569L11.5646 2.89756C11.6188 2.95173 11.6572 3.01041 11.68 3.07361C11.7027 3.1368 11.7139 3.20451 11.7135 3.27673C11.7135 3.34895 11.7022 3.41666 11.6794 3.47986C11.6567 3.54305 11.6184 3.60173 11.5646 3.6559L10.1292 5.09131C10.0208 5.19965 9.89444 5.25165 9.75 5.24731C9.60556 5.24298 9.47917 5.18647 9.37083 5.07777ZM10.8063 11.3746C9.64167 11.3746 8.50651 11.115 7.40079 10.5957C6.29507 10.0765 5.31556 9.39034 4.46225 8.5374C3.60894 7.68445 2.92283 6.70494 2.40392 5.59886C1.885 4.49277 1.62536 3.35762 1.625 2.1934C1.625 2.0309 1.67917 1.89548 1.7875 1.78715C1.89583 1.67881 2.03125 1.62465 2.19375 1.62465H4.3875C4.51389 1.62465 4.62674 1.66527 4.72604 1.74652C4.82535 1.82777 4.88403 1.93159 4.90208 2.05798L5.25417 3.95381C5.27222 4.0802 5.26988 4.1954 5.24713 4.2994C5.22438 4.4034 5.17256 4.49584 5.09167 4.57673L3.79167 5.90381C4.17083 6.55381 4.64479 7.16319 5.21354 7.73194C5.78229 8.30069 6.40972 8.7927 7.09583 9.20798L8.36875 7.93506C8.45 7.85381 8.55617 7.79297 8.68725 7.75252C8.81833 7.71208 8.94689 7.7007 9.07292 7.7184L10.9417 8.09756C11.0681 8.12465 11.1719 8.18568 11.2531 8.28065C11.3344 8.37562 11.375 8.48612 11.375 8.61215V10.8059C11.375 10.9684 11.3208 11.1038 11.2125 11.2121C11.1042 11.3205 10.9688 11.3746 10.8063 11.3746Z" fill="white" fillOpacity="0.9"/>
            </svg>
            <p class="brown-bar-text">Phone: (714) 895-3555</p>
          </div>
        </div>
      </div>
      <div className='nav-bar'>
        <div className='nav-container'>
          <div>
            <NavLink to='/' className='logo'>
              <svg className='logo-svg' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" fill="#674736"/>
                <path d="M12.3672 32V8H27.9766V11.6445H16.7148V18.1602H27.168V21.8047H16.7148V28.3555H28.0703V32H12.3672Z" fill="white"/>
              </svg>
              <p className='logo-text'> Eureka Bearing</p>
            </NavLink>
          </div>
          <div className='nav-right-container'>
            <div className='searchbar' onClick={()=> setOpenSearch(true)}>
              { !openSearch && !isLargeScreen ? searchIcon :<SearchBar allProducts={allProducts} /> }
            </div>
            <div className='menubar' onClick={()=> setOpenMenu(!openMenu)} >
              {
                isLargeScreen ? normalMenu : 
                (!openMenu ? menuBar : <TabletNavbar allProducts={allProducts} setOpenMenu={setOpenMenu} openMenu={openMenu} />)
              }
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
