import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useState } from 'react'
import CatDropDown from '../catDropDown';
import { leftCaret } from '../../../Images/svg';
import './tabletNavbar.css'

const TabletNavbar = ({ allProducts, setOpenMenu, openMenu }) => {

    const [openCategoryMenu, setOpenCategoryMenu] = useState(false)
    const buttonRef = useRef(null);

    useEffect(() => {
        if (!openMenu) return;
    
        const handleOutsideClick = (event) => {
            if (buttonRef.current && !buttonRef.current.contains(event.target)) {
                setOpenMenu(false);
            }
        };
    
        const timer = setTimeout(() => {
            document.addEventListener('click', handleOutsideClick);
        }, 0);
    
        return () => {
            clearTimeout(timer);
            document.removeEventListener('click', handleOutsideClick);
        };
    
    }, [openMenu]);

    return (
        <div className='menu-container' ref={buttonRef} onClick={(e) => e.stopPropagation()}>
            <div className='menu-header' onClick={() => setOpenMenu(false)}  >
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.56667 25.6667L0 23.1L10.2667 12.8333L0 2.56667L2.56667 0L12.8333 10.2667L23.1 0L25.6667 2.56667L15.4 12.8333L25.6667 23.1L23.1 25.6667L12.8333 15.4L2.56667 25.6667Z" fill="#674736"/>
                </svg>
            </div>
            <div className='menu-body'>
                <NavLink to="/" className='menu-body-text' onClick={() => setOpenMenu(false)}>
                    Home
                </NavLink>
                <div className='menu-body-categories' onClick={() => {setOpenCategoryMenu(!openCategoryMenu) }}>
                    <p className='menu-body-text'> Categories </p>
                    {leftCaret}
                    {openCategoryMenu ? <CatDropDown allProducts={allProducts} setOpenMenu={setOpenMenu}/> : '' }
                </div>
                <NavLink to="/Product-Line-Card" className='menu-body-text' onClick={() => setOpenMenu(false)}>
                    Product Line Card
                </NavLink>
                <NavLink to="/about" className='menu-body-text' onClick={() => setOpenMenu(false)}>
                    About
                </NavLink>
                <NavLink to="/contact" className='menu-body-text' onClick={() => setOpenMenu(false)}>
                    Contact
                </NavLink>
            </div>
        </div>
    );
}

export default TabletNavbar;
