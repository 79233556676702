import React, { useEffect, useState } from 'react';
import NavBar from '../../Components/navbar';
// import { loaderGif } from '../../Components/loadingPage/index'
import Footer from '../../Components/footer/footer';
import img from './img.png'
import './404.css'
import { useNavigate } from 'react-router-dom';

function NotFound() {
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        (setTimeout(() => {
            setIsLoaded(true)
        }, 1000))
    }, []);

    return isLoaded && (
        <>
                    <div className='background-image'>
                        <NavBar />
                        <div className='navbar-spacing'>
                            <div className='lost-page-container'>
                                <div className='lost-page-header-container'>
                                    <div className='lost-page-num'>4</div>
                                    <div className='lost-page-num'>0</div>
                                    <div className='lost-page-num-right'>4</div>
                                </div>
                                <div className='lost-page-text'>
                                    Page Not Found
                                </div>
                                <img className='lost-image' src={img} />
                                <button className='lost-brown-button' onClick={() => navigate('/')}> Back to Home</button>
                            </div>
                        </div>
                        <Footer />
                    </div>
        </>

    )
}

export default NotFound;
